<template>
  <div class="translateCoinBox">
    <NavBar title="划转"></NavBar>
    <div class="contentBox">
      <Space :height="30"></Space>
      <TranslateCoin ref="translateCoin"></TranslateCoin>
    </div>
    <van-button type="primary" block class="fixedBottom" @click="translate" :loading="$store.state.submitLoading">
      划转
    </van-button>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {};
  },
  // created() {},
  mounted() {
    this.$store.commit('setBodyColor');
  },
  methods: {
    translate() {
      this.$refs.translateCoin.submit();
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
